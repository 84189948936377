export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
    resource: 'Home',
    action: 'read',
  },
  {
    title: 'Master',
    icon: 'ArchiveIcon',
    resource: 'Home',
    action: 'read',
    children: [
      {
        title: 'Universitas',
        route: 'universitas',
        icon: 'GridIcon',
        resource: 'Admin',
        action: 'read',
      },
      {
        title: 'Fakultas',
        route: 'fakultas',
        icon: 'GridIcon',
        resource: 'Admin',
        action: 'read',
      },
      {
        title: 'Jurusan',
        route: 'jurusan',
        icon: 'GridIcon',
        resource: 'Admin',
        action: 'read',
      },
      {
        title: 'Platform',
        route: 'platform',
        icon: 'GridIcon',
        resource: 'Member',
        action: 'read',
      },
      {
        title: 'Platform Version',
        route: 'platformVersion',
        icon: 'GridIcon',
        resource: 'Member',
        action: 'read',
      },
    ],
  },
  {
    title: 'Members',
    icon: 'UsersIcon',
    resource: 'Home',
    action: 'read',
    children: [
      {
        title: 'Pendidikan',
        route: 'pendidikan',
        icon: 'AwardIcon',
        resource: 'Pendidikan',
        action: 'read',
      },
      {
        title: 'Member',
        route: 'member',
        icon: 'UsersIcon',
        resource: 'Member',
        action: 'read',
      },    
    ],
  },

  {
    title: 'Mentorship',
    icon: 'BookIcon',
    resource: 'Home',
    action: 'read',
    children: [
      {
        title: 'Mentor Register',
        route: 'mentorship-register',
        icon: 'UserCheckIcon',
        resource: 'Mentorship',
        action: 'read',
      },
      {
        title: 'Mentorship List',
        route: 'mentorship-schedule',
        icon: 'ClockIcon',
        resource: 'Mentorship',
        action: 'read',
      },
    ],
  },
  
  
  {
    title: 'Log',
    route: 'log',
    icon: 'ListIcon',
    resource: 'Member',
    action: 'read',
  },
  
]
